import React from 'react';
import PropTypes from 'prop-types';

const ChevronIcon = (props) => {

  /* eslint-disable max-len*/
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' style={props.style}>
      <path d='M24 10.3l24 23.5-3.8 3.9L24 18 3.8 37.7 0 33.8z'></path>
      <path d='M24 10.3l24 23.5-3.8 3.9L24 18 3.8 37.7 0 33.8z'></path>
    </svg>
  );
  /*eslint-enable max-len*/
};

ChevronIcon.propTypes = {
  style: PropTypes.object
};

ChevronIcon.defaultProps = {
  style: {}
};

export default ChevronIcon;
