import React from 'react';
import PropTypes from 'prop-types';

const ChatIcon = (props) => {

  /* eslint-disable max-len*/
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' style={props.style}>
      <path d='M41.0827,34.5924h46.78a8.3681,8.3681,0,0,1,8.3436,8.3435V70.123a8.3681,8.3681,0,0,1-8.3436,8.3436H85.695l5.7112,14.1487L75.2685,78.4666H41.0827a8.3681,8.3681,0,0,1-8.3436-8.3436V42.9359A8.3681,8.3681,0,0,1,41.0827,34.5924Z' />
      <path d='M31.2391,42.9359a9.8551,9.8551,0,0,1,9.8437-9.8438H77.1811v-16.06a9.676,9.676,0,0,0-9.6477-9.6477H13.441a9.6761,9.6761,0,0,0-9.6477,9.6477V48.4689a9.6761,9.6761,0,0,0,9.6477,9.6478h2.507l-6.6038,16.36,18.66-16.36h3.2349Z' />
    </svg>
  );
  /*eslint-enable max-len*/
};

ChatIcon.propTypes = {
  style: PropTypes.object
};

ChatIcon.defaultProps = {
  style: {
    width: '2.4rem',
    height: '2.4rem'
  }
};

export default ChatIcon;
