import styles from './VirtualAssistantNotice.module.scss';
import classNames from 'classnames/bind';
import React, { useState } from 'react';

const cx = classNames.bind(styles);

const VirtualAssistantNotice = (props) => {
  const [displayBanner, setDisplayBanner] = useState(true);
  const handleClose = (e) => {
    e.preventDefault();
    setDisplayBanner(false);
  };

  return (
    <div {...props}>
      <div className={cx('alertbox',  { hide: !displayBanner })}>
        <button className={cx('closebtn')} onClick={handleClose}>&times;</button>
        <>This virtual assistant is not monitored by a live person.
          In case of emergency, call <a href='tel:+911'>911</a> or go to the nearest emergency room.
        </>
      </div>
    </div>
  );
};

export default VirtualAssistantNotice;
