import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './Common.scss';
import Main from './Main';
import React from 'react';
import { createRoot } from 'react-dom/client';

class Chatbot {
  constructor() {
    this.chatbotId = `portal-chatbot-${Math.random().toString(36).substr(2)}`;
    this.chatbotElement = null;
  }

  isInitialized() {
    return !!this.chatbotElement;
  }

  init(options = {}) {
    if (this.isInitialized()) {
      return;
    }

    // setup default option values
    options = Object.assign({
      containerClassName: 'portal-chatbot-container',
      containerStyle: { position: 'absolute' }
    }, options);

    // separate container options from Main options
    const { containerClassName, containerStyle, ...customOptions } = options;

    // setup and append new chatbot div to body
    this.chatbotElement = document.createElement('div');
    this.chatbotElement.id = this.chatbotId;
    this.chatbotElement.className = containerClassName;
    if (containerStyle) {
      Object.keys(containerStyle).forEach(key => {
        this.chatbotElement.style[key] = containerStyle[key];
      });
    }
    document.body.appendChild(this.chatbotElement);

    const root = createRoot(this.chatbotElement);
    root.render(
      <React.StrictMode>
        <Main {...customOptions} />
      </React.StrictMode>
    );
  }

  show() {
    if (!this.isInitialized()) {
      return;
    }

    this.chatbotElement.style.display = 'block';
  }

  hide() {
    if (!this.isInitialized()) {
      return;
    }

    this.chatbotElement.style.display = 'none';
  }
}

const chatbot = new Chatbot();
export { chatbot as Chatbot };
