import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './ChatEntry.module.scss';

const cx = classNames.bind(styles);

const ChatEntry = ({ children, align = 'left' }) => {
  const messageClass = cx('chat-entry', align === 'left' ? 'align-left' : 'align-right');
  return (
    <div className={cx('chat-entry-container')}>
      <div className={messageClass}>{children}</div>
    </div>
  );
};

ChatEntry.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  align: PropTypes.oneOf(['left', 'right']),
};

export default ChatEntry;
