import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import styles from './ButtonGroup.module.scss';

const cx = classNames.bind(styles);

const ButtonGroup = ({ suggestions, handleResponse }) => {

  return (
    <div className={cx('suggestion-container')}>
      {suggestions.map((suggestion, i) => {
        return (
          <button
            key={i}
            className={cx('suggestion-button', 'bot-button')}
            onClick={(e) => handleResponse(e)}
          >
            {suggestion}
          </button>
        );
      })}
    </div>
  );
};

ButtonGroup.propTypes = {
  suggestions: PropTypes.array,
  handleResponse: PropTypes.func.isRequired
};

export default ButtonGroup;
