import { LoaderDots } from '@thumbtack/thumbprint-react';
import AWS, { LexRuntimeV2 } from 'aws-sdk';
import classNames from 'classnames/bind';
import ChatBar from './ChatBar';
import ChatEntry from './ChatEntry';
import ChatIcon from './ChatIcon';
import BotResponse from './BotResponse';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import styles from './Main.module.scss';
import { debounce } from 'debounce';
import { getFakeResponse } from './mockResponses.js';
import ButtonGroup from './ButtonGroup';
import VirtualAssistantNotice from './VirtualAssistantNotice';

const USE_MOCK_RESPONSES = process.env.NODE_ENV === 'development';

const cx = classNames.bind(styles);
const requiredChars = /[0-9A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]+/;
const botDisplayName = 'COVID-19 Virtual Assistant';

AWS.config.region = 'us-east-1';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-1:da18690e-a839-4764-9d7e-34177a3917ac'
});

const lexParams = {
  botId: 'UAQ2ZX2ETL',
  botAliasId: 'ZCJQ0GQOJ8', // Dev: RRVYXLHRND, TestBotAlias: TSTALIASID
  localeId: 'en_US',
  sessionId: Math.random().toString(),
  text: ''
};

const askForHelp = 'help';

const Main = (props) => {
  const [userResponse, setUserResponse] = useState('');
  const [conversation, setConversation] = useState([]);
  const [thinking, setThinking] = useState(false);
  const [isMaximized, setMaxmimized] = useState(props.isMaximized);
  const [display, setDisplay] = useState(true);

  const [displayScroll, setDisplayScroll] = useState(false);
  const displayScrollRef = useRef(displayScroll);
  const waitingToDisplayScroll = useRef(false);
  const myTimer = useRef(null);
  const chatLogId = 'tide-chat-log';

  const endOfChat =  useRef(null);
  const convoLengthRef = useRef(null);
  convoLengthRef.current = conversation.length;

  const userInput = useRef(null);

  const handleScroll = () => {
    const chatLog = document.getElementById(chatLogId);
    const scrollTop = chatLog.scrollTop;
    const height = chatLog.scrollHeight;
    const clientHeight = chatLog.clientHeight;
    const atBottom = height - clientHeight - scrollTop < 10;
    if (atBottom && (displayScrollRef.current || waitingToDisplayScroll.current)) {
      clearTimeout(myTimer.current);
      waitingToDisplayScroll.current = false;
      setDisplayScroll(false);
    } else if (!displayScrollRef.current && !atBottom && !waitingToDisplayScroll.current) {
      waitingToDisplayScroll.current = true;
      myTimer.current = setTimeout(() => setDisplayScroll(true), 1000);
    }
  };

  useEffect(() => {
    const body = document.body;
    const html = document.documentElement;

    const handlePageScroll = debounce(() => {
      // if our current position is less than the full page, show the chat bot icon
      setDisplay((window.pageYOffset + window.innerHeight) <= Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      ) - 40);
    }, 100);
    document.addEventListener('scroll', handlePageScroll, { passive: true });
    setTimeout(handlePageScroll, 3500);
    return () => document.removeEventListener('scroll', handlePageScroll);
  }, []);

  const shouldAskForHelp = isMaximized && conversation.length === 0 && !thinking;
  useEffect(() => {
    if (shouldAskForHelp) {
      getLexResponse(askForHelp);
    }
  }, [shouldAskForHelp]);

  useEffect(() => {
    const timer = setTimeout(() => scrollToBottom(), 10);
    return () => clearTimeout(timer);
  }, [conversation, thinking]);

  useEffect(() => {
    document.getElementById(chatLogId).addEventListener('scroll', debounce(handleScroll, 200), { passive: true });
    return () => document.getElementById(chatLogId).removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isMaximized) {
      focusUserInput();
    }
  }, [isMaximized]);

  const submitUserResponse = (e) => {
    e.preventDefault();
    if (!requiredChars.test(userResponse)) {
      return;
    }
    addUserEntry(userResponse);
    getLexResponse(userResponse);
    setUserResponse('');
  };

  const getHelp = (e) => {
    e.preventDefault();
    getLexResponse(askForHelp);
  };

  const botResponseClick = (e, responseText) => {
    e.preventDefault(e);
    const message = responseText ? responseText : e.target.innerText;
    addUserEntry(message);
    getLexResponse(message);
  };

  const getResponse = (reqText) => {
    return (USE_MOCK_RESPONSES ? getFakeResponse(reqText) : new LexRuntimeV2().recognizeText(lexParams).promise());
  };

  const getLexResponse = async(reqText) => {
    try {
      setThinking(true);
      lexParams.text = reqText;
      const lexResponse = await getResponse(reqText);
      let { messages } = lexResponse;
      messages.forEach(message => {
        let parsedMessage = '';
        if (message.contentType === 'CustomPayload') {
          try {
            parsedMessage = JSON.parse(message.content);
          } catch (e) {
            return;
          }
        } else if (message.contentType === 'PlainText') {
          parsedMessage = message.content;
        } else {
          return;
        }
        setConversation(convo => {
          convo = updatePrevEntry(convo);
          let suggestions = [];
          if (Object.prototype.hasOwnProperty.call(parsedMessage, 'suggestions')) {
            suggestions = [
              <ChatEntry key={convo.length + 1}>
                <ButtonGroup suggestions={parsedMessage.suggestions} handleResponse={botResponseClick} />
              </ChatEntry>,
            ];
          }

          return [
            ...convo,
            <ChatEntry key={convo.length}>
              <BotResponse
                htmlString={
                  Object.prototype.hasOwnProperty.call(parsedMessage, 'html') ? parsedMessage.html : parsedMessage}
                handleResponse={botResponseClick}
                suggestions={null}
              />
            </ChatEntry>,
            ...suggestions
          ];
        });
      });
    } catch (err) {
      console.log(err);
    } finally {
      setThinking(false);
    }
  };

  const scrollToBottom = () => {
    isMaximized && focusUserInput();
    setTimeout(() => endOfChat.current.scrollIntoView(), 100);
  };

  function handleMaximize(event) {
    event.preventDefault();
    setMaxmimized(true);
  }

  function handleMinimize(event) {
    event.preventDefault();
    setMaxmimized(false);
  }

  function focusUserInput() {
    userInput.current.focus();
  }

  const updatePrevEntry = (convo) => {
    if (convo[convo.length - 1]?.type === ChatEntry) {
      const prevEntry = convo[convo.length - 1];
      if (prevEntry.props?.children?.type === ButtonGroup) {
        convo.pop();
      }
      if (prevEntry.props?.children?.type === BotResponse) {
        const botResponse = React.cloneElement(prevEntry.props.children, { latestEntry: false });
        convo[convo.length - 1] = React.cloneElement(prevEntry, { children: botResponse });
      }
    }
    return convo;
  };
  const addUserEntry = (message) => {
    setConversation(convo => {
      convo = updatePrevEntry(convo);
      return [...convo, <ChatEntry key={convo.length} align='right'>{message}</ChatEntry>];
    });
  };

  const titleClassName = isMaximized ? cx('title') : cx('title-mini');
  const mainClassName = isMaximized ? cx('main') : cx('main-mini');

  return (
    <>
      <div className={cx('container', { 'container-minimize': !isMaximized, 'hidden': !display && !isMaximized })}>
        <div className={mainClassName}>
          <div className={titleClassName}>
            {isMaximized === true &&
              <button
                title={botDisplayName}
                className={cx('header-button')}
                onClick={isMaximized ? handleMinimize : handleMaximize}
              >
                {botDisplayName}
              </button>
            }

            <div className={cx('actions')}>
              {isMaximized ?
                <>
                  <button title='help' className={cx('icon-button')} onClick={getHelp}>?</button>
                  <button title='minimize' className={cx('collapse-button', 'icon-button')} onClick={handleMinimize}>
                    <div className={cx('arrow', 'down')}>minimize</div>
                  </button>
                </>              :
                <button
                  title={botDisplayName}
                  className={cx('icon-button-chat')}
                  onClick={handleMaximize}
                >
                  <ChatIcon />
                </button>
              }
            </div>
          </div>
          <div id={chatLogId} className={cx('chat-log', { hide: !isMaximized })}>
            <VirtualAssistantNotice className={cx('assistant-notice-box')} />
            {conversation}
            {thinking && (
              <ChatEntry>
                <div className={cx('loader-dots-container')}><LoaderDots size='small' theme='muted' /></div>
              </ChatEntry>
            )}
            <div ref={endOfChat} />
          </div>
          <form className={cx('bot-form', { hide: !isMaximized })} onSubmit={submitUserResponse}>
            <input
              className={cx('question-input-field')}
              type='text'
              placeholder='Enter your question here'
              value={userResponse}
              onChange={(e) => setUserResponse(e.target.value)}
              ref={userInput}
              maxLength='1024'
              required
            />
            <button
              className={cx('submit-button')}
              type='submit'
              disabled={!requiredChars.test(userResponse)}
            >
              SEND
            </button>
            <button
              type='button'
              className={cx('scroll-button', { 'fade-out': !displayScroll })}
              onClick={scrollToBottom}
            >
              <span className={cx('rotate90')}>➜</span> See latest message
            </button>
          </form>
        </div>
      </div>
      <ChatBar display={display} isMaximized={isMaximized} handleMaximize={handleMaximize} />
    </>
  );
};

Main.propTypes = {
  isMaximized: PropTypes.bool
};

Main.defaultProps = {
  isMaximized: false
};

export default Main;
