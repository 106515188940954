import PropTypes from 'prop-types';
import React from 'react';
import Parser, { domToReact } from 'html-react-parser';
import classNames from 'classnames/bind';
import styles from './BotResponse.module.scss';
import IconExternalLink from 'terra-icon/lib/icon/IconExternalLink';

const cx = classNames.bind(styles);

const handleShowContent = (e, id) => {
  e.preventDefault();
  const parent = e.target.closest('[class*="chat-entry"]');

  if (!parent) {
    return;
  }
  const hiddenContent = parent.querySelectorAll(`[data-show-content=${id}]`);
  hiddenContent.forEach((element) => {
    element.classList.remove('hidden-content');
  });
  e.target.remove();
};

const BotResponse = ({ htmlString, handleResponse, latestEntry = true }) => {
  const parseOptions = {
    replace: ({ attribs, children, name }) => {
      if (!attribs) {
        return;
      }

      if (name === 'a') {
        const linkText = <span className={cx('link-text')}>{domToReact(children, parseOptions)}</span>;
        if (Object.prototype.hasOwnProperty.call(attribs, 'data-show-toggle')) {
          return (
            <a
              href='/#'
              onClick={(e) => handleShowContent(e, attribs['data-show-toggle'])}
              className={cx('seeMore')}
            >
              {linkText}
            </a>
          );
        }

        if (attribs.href) {
          if (attribs.target === '_blank') {
            let rels = new Set(attribs.rel?.split(' '));
            ['noreferrer', 'noopener'].forEach(rel => rels.add(rel));
            attribs.rel = [...rels].join(' ');
          }
          return (
            <a {...attribs} className={cx('bot-link', 'external-link')}>
              {linkText}
              {
                Object.prototype.hasOwnProperty.call(attribs, 'data-external') &&
                <IconExternalLink
                  className={cx('external-link-icon')}
                />
              }
            </a>
          );
        } else {
          return (
            <a
              href='/#'
              onClick={(e) => handleResponse(e, attribs['data-response'])}
              className={cx('bot-link')}
            >
              {linkText}
            </a>
          );
        }
      }

      if (name === 'button') {
        let disabled = false;
        if (Object.prototype.hasOwnProperty.call(attribs, 'data-disable-historical') && !latestEntry) {
          disabled = true;
        }
        return (
          <button
            {...attribs}
            disabled={disabled}
            onClick={(e) => handleResponse(e, attribs['data-response'])}
            className={cx('inline-button', 'bot-button', disabled && 'disabled')}
          >
            {domToReact(children, parseOptions)}
          </button>
        );
      }
    }
  };

  return Parser(htmlString, parseOptions);
};

BotResponse.propTypes = {
  htmlString: PropTypes.string.isRequired,
  handleResponse: PropTypes.func.isRequired,
  latestEntry: PropTypes.bool
};

export default BotResponse;
