// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".portal-chatbot_src__ButtonGroup-module__suggestion-container--E53or{display:flex;flex-direction:column}.portal-chatbot_src__ButtonGroup-module__suggestion-button--aWFh5{border-radius:14px;display:inline-block;min-width:170px;padding:6px;width:100%}.portal-chatbot_src__ButtonGroup-module__suggestion-button--aWFh5:not(:last-child){margin-bottom:6px}", "",{"version":3,"sources":["webpack://./src/ButtonGroup.module.scss"],"names":[],"mappings":"AAGE,qEACE,YAAA,CACA,qBAAA,CAGF,kEACE,kBAAA,CACA,oBAAA,CACA,eAAA,CACA,WAAA,CACA,UAAA,CAEA,mFACE,iBAAA","sourcesContent":["@import './variables';\n\n:local {\n  .suggestion-container {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .suggestion-button {\n    border-radius: 14px;\n    display: inline-block;\n    min-width: 170px;\n    padding: 6px;\n    width: 100%;\n\n    &:not(:last-child) {\n      margin-bottom: 6px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"suggestion-container": "portal-chatbot_src__ButtonGroup-module__suggestion-container--E53or",
	"suggestion-button": "portal-chatbot_src__ButtonGroup-module__suggestion-button--aWFh5"
};
export default ___CSS_LOADER_EXPORT___;
