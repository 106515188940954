import React, { useState } from 'react';
import classNames from 'classnames/bind';
import ChevronIcon from './ChevronIcon';
import styles from './ChatBar.module.scss';
import PropTypes from 'prop-types';
import ChatIcon from './ChatIcon';

const cx = classNames.bind(styles);

const ChatBar = ({ display, isMaximized, handleMaximize }) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <div className={cx('chat-bar', { 'hidden': display || isMaximized, 'expanded': expanded })}>
      <div className={cx('chat-bar-title', { 'hidden': display })} onClick={() => setExpanded(!expanded)}>
        <button className={cx('chat-bar-button')} onClick={handleMaximize}>
          <div className={cx('chat-bar-button-icon')}>
            <ChatIcon style={{
              width: '1.4rem',
              height: '1.4rem'
            }}
            />
          </div>
          <div className={cx('chat-bar-button-text')}>
            Open COVID-19 Virtual Assistant
          </div>
        </button>
        <button
          title={expanded ? 'minimize' : 'maximize'}
          className={cx('chat-bar-expand-icon', { 'close': expanded })}
          onClick={() => setExpanded(!expanded)}
        >
          <ChevronIcon />
        </button>
      </div>
    </div>
  );
};

ChatBar.propTypes = {
  display: PropTypes.bool.isRequired,
  isMaximized: PropTypes.bool.isRequired,
  handleMaximize: PropTypes.func.isRequired
};

export default ChatBar;
